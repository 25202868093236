/**
 * @module SetUnitSlice
 *
 * @description Redux state of the set unit modal.
 * Because of modal's complexity the React hook form state is duplicated here, so the modal can be rerendered without data loss
 */

import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { regExps } from '../../constants'

/**
 * @type SetUnitField
 * @description list of possible field id's for set unit modal
 */
type SetUnitField = 'title_deed' | 'owners_valid' | 'Property' | 'Images' | 'Statuses' | 'Number' | 'Bedrooms' | 'Bathrooms' | 'Statuses[1]' | 'Statuses[2]' | 'Features' | 'add_owner_button' | 'SizeArea' | 'Type' | 'Amenities' | 'Appliances' | 'GuestBathroom' | 'MaidsRoom' | 'PropertyInfo' | 'property_valid' | 'AdvertisePrice' | 'Fit' | 'View' | 'Utilities.ACCount' | 'add_poa_button' | 'poa-label' | 'ImagesOnly' | 'AdvertisementSpaces' | 'DisabledWeekdays' | 'DisabledTimes' | 'property_type' | 'special_requests' | 'tenant_requirements'

/**
 * @interface StaticFormItem
 *
 * @property {SetUnitField} (id) id of a field
 * @property {ModalRow<SetUnitField>} row the row that will be rendered to be displayed by the modal's render core
 * @property {any} (value) the value of the field. The value updates with the user input and fills initialState after modal re-renders
 * @property {'Landlord' | 'Agent'} (userType) If specified, the field will render only for the specified users
 */
export interface StaticFormItem {
    id?: SetUnitField
    row: ModalRow<SetUnitField>
    value?: any
    /** if specified, the field renders only for the given user type */
    userType?: 'Landlord' | 'Agent'
}

/**
 * @interface StaticFormItem
 *
 * @property {SetUnitField} (id) id of a field
 * @property {ModalRow<string>} row the row that will be rendered to be displayed by the modal's render core
 * @property {any} (value) the value of the field. The value updates with the user input and fills initialState after modal re-renders
 * @property {boolean} show the flag that specifies if the field should be rendered or not
 * @property {string} (placeAfter) the id of a static or dynamic filed this field will be rendered after
 * @property {string} (placeBefore) the id of a static or dynamic filed this field will be rendered before
 */
export interface DynamicFormItem {
    id: string
    row: ModalRow<string>
    value: any
    show: boolean
    placeAfter?: string
    placeBefore?: string
}

/**
 * @interface ModalRow
 * @description This interface represents the modal render core's single row - single element properties
 *
 * @template id_type the type of ModalRow's id usually string or SetUnitField
 *
 * @property {id_type} (id) id of a field
 * @property {string[]} (content) content usually the input's label, placeholders e.t.c.
 * @property {string} element the codename of the element that will be rendered
 * @property {boolean} (multiple) for selects only, ANTD selector's multiple property
 * @property {boolean} (required) indicates if a field is required or not
 * @property {string} (modal) the id of modal that can be opened via rendered element e.g. select-add that opens a modal in ANTD drawer
 * @property {string} (regexp) the regexp that will run on field validation
 * @property {string[] | Array<{ label: string, value: string }>} (selectors) for selects only, selector's options
 * @property {number} (minLength) minimal length of value in input element
 */
export interface ModalRow<id_type> {
    id?: id_type
    content?: string[]
    element: string
    multiple?: boolean
    required?: boolean
    modal?: string
    regexp?: string
    selectors?: string[] | Array<{ label: string, value: string }>
    minLength?: number
    showSearch?: boolean
}

/**
 * @interface DocumentFields
 * @description This interface represents the scanned TitleDeed fields that are not represented in form though still have to be saved in DB
 *
 * @property {string} Scanned.DocumentType Document type
 * @property {string} Scanned.DocumentYear Document issue year
 * @property {string} Scanned.DocumentNumber Document number
 */
export interface DocumentFields {
    'Scanned.DocumentType': string
    'Scanned.DocumentYear': string
    'Scanned.DocumentNumber': string
}

/**
 * @interface Owner
 * @description This interface represents Landlord (Owner)
 *
 * @property {number} index unique number of owner that represents owners order in form
 * @property {string} id unique id of owner that looks like "Owners[${index}]"
 * @property {OwnerValues} values the set of fields containing all the information about owner
 */
export interface Owner {
    index: number
    id: string
    values: OwnerValues
}

/**
 * @interface OwnerValues
 *
 * @property {string} (Name) Name
 * @property {string} (Phone) Phone
 * @property {string} (Email) Email
 * @property {number | undefined} (emirates_id) emirates id document id
 * @property {number | undefined} (passport) passport document id
 * @property {string | undefined} (uploaded_emirates_id) emirates id link
 * @property {string | undefined} (uploaded_passport) passport link
 */
export interface OwnerValues {
    Name?: string
    Phone?: string
    Email?: string
    // DocumentNumber?: string
    emirates_id?: number
    passport?: number
    uploaded_passport?: string
    uploaded_emirates_id?: string
    IsDecisionMaker?: boolean
}

export type OwnerValuesKey = 'Name' | 'Phone' | 'Email' /* | 'DocumentNumber' */ | 'passport' | 'emirates_id' | 'IsDecisionMaker'

/**
 * @property {number | undefined} (passport) Passport id
 * @property {number | undefined} (emirates_id) Emirates id id
 * @property {number | undefined} (poa) POA id
 */
export interface Representative {
    passport?: number
    emirates_id?: number
    poa?: number
}

/**
 * @interface SetUnitState
 *
 * @property {StaticFormItem[]} staticForm base form
 * @property {DynamicFormItem[]} dynamicForm dynamic form elements with control over showing or hiding and position in form
 * @property {DocumentFields} documentFields additional fields for TitleDeed
 * @property {Owner[]} owners array of owners
 * @property {Representative} representative representative's documents info
 * @property {Record<string, string>} uploadedDocuments a map where field id is a key and base64 file is a value
 * @property {Record<string, Array<ModalRow<string>>>} footerTemplates stored footers for both add and edit unit modals
 * @property {number} formKey is used to re-render modal. Any change of the key triggers modal's render
 * @property {boolean} titleDeedLoaded flag indicates that title deed has been scanned
 * @property {boolean} imagesLoaded (unused)
 * @property {boolean} loading blocks any action on modal while loading processes
 * @property {any} modal modal's skeleton
 * @property {boolean} changeDetected flag that is indicating if anything has been changed by user
 */
interface SetUnitState {
    staticForm: StaticFormItem[]
    dynamicForm: DynamicFormItem[]
    documentFields: DocumentFields
    owners: Owner[]
    representative: Representative
    uploadedDocuments: Record<string, string>
    footerTemplates: Record<string, Array<ModalRow<string>>>
    formKey: number
    titleDeedLoaded: boolean
    imagesLoaded: boolean
    loading: boolean
    modal: any
    changeDetected: boolean
}

const initialState: SetUnitState = {
    staticForm: [
        {
            row: { content: ['Upload / Scan the title deed and fill the following details to add unit'], element: 'label-primary' }
        },
        {
            id: 'title_deed',
            row: { id: 'title_deed', content: ['Unit Title Deed'], element: 'document-scan-input', required: true },
            value: undefined,
            userType: 'Landlord'
        },
        {
            id: 'Property',
            row: {
                id: 'Property', content: ['Building or Community', 'Select unit\'s building or community'], element: 'select-add', required: true, showSearch: true
            },
            value: undefined
        },
        {
            id: 'PropertyInfo',
            row: {
                id: 'PropertyInfo', content: [], element: 'label-badge'
            },
            value: undefined
        },
        {
            id: 'property_valid',
            row: {
                id: 'property_valid', content: ['Property Location can\'t be empty'], element: 'hidden-input', required: true
            },
            value: undefined
        },
        {
            id: 'property_type',
            row: {
                id: 'property_type', content: ['Unit Type', 'Select unit\'s property type'], element: 'select', required: true, showSearch: true
            },
            value: undefined
        },
        {
            id: 'SizeArea',
            row: {
                id: 'SizeArea', content: ['Unit area', 'sqr.ft', 'Enter unit’s size'], element: 'input-group', required: true, regexp: regExps.isFloatNumber
            }
        },
        {
            id: 'Type',
            row: {
                id: 'Type', content: ['Unit category', 'Select unit\'s category'], selectors: ['Residential', 'Commercial'], element: 'select', required: true
            }
        },
        {
            id: 'Number',
            row: { id: 'Number', content: ['Unit number', 'Enter unit number'], minLength: 1, element: 'input', required: true, regexp: regExps.isFloatNumber },
            value: undefined
        },
        {
            id: 'Bedrooms',
            row: { id: 'Bedrooms', content: ['Number of Bedrooms', 'Enter the number of bedrooms or “Studio”'], minLength: 1, element: 'input', required: true, regexp: regExps.isNumberOfBedrooms },
            value: undefined
        },
        {
            id: 'Bathrooms',
            row: { id: 'Bathrooms', content: ['Number of Bathrooms', 'Enter the number of bathrooms'], minLength: 1, element: 'input', required: true, regexp: regExps.isFloatNumber },
            value: undefined
        },
        {
            id: 'GuestBathroom',
            row: { id: 'GuestBathroom', content: ['Unit with guest bathroom'], selectors: ['Yes', 'No'], element: 'select', required: true },
            value: 'No'
        },
        {
            id: 'MaidsRoom',
            row: { id: 'MaidsRoom', content: ['Unit with maids room'], selectors: ['Yes', 'No'], element: 'select', required: true },
            value: 'No'
        },
        {
            id: 'Utilities.ACCount',
            row: { id: 'Utilities.ACCount', content: ['Number of A/c units installed in the property.', 'Input AC Count'], regexp: regExps.isFloatNumber, element: 'input' },
            value: undefined
        },
        {
            id: 'AdvertisePrice',
            row: { id: 'AdvertisePrice', content: ['Advertisement price', 'Select advertisement price'], element: 'select', selectors: ['1', '2', '3'], required: true },
            value: undefined
        },
        {
            id: 'Statuses',
            userType: 'Landlord',
            row: {
                id: 'Statuses',
                content: ['Status', 'Select status'],
                selectors: [
                    {
                        label: 'Vacant and Door open',
                        value: 'Vacant-Door open'
                    },
                    {
                        label: 'Vacant and Building Management',
                        value: 'Vacant-Building Management'
                    },
                    /* {
                        label: 'Vacant and Door close',
                        value: 'Vacant'
                    }, */
                    /* {
                        label: 'Occupied and Door open',
                        value: 'Occupied-Door open'
                    }, */
                    {
                        label: 'Vacant and Keys in BSO office',
                        value: 'Vacant-Keys in BSO office'
                    },
                    /* {
                        label: 'Occupied and Keys in BSO office',
                        value: 'Occupied-Keys in BSO office'
                    }, */
                    {
                        label: 'Vacant and Digital lock',
                        value: 'Vacant-Digital lock'
                    },
                    /* {
                        label: 'Occupied and Digital lock',
                        value: 'Occupied-Digital lock'
                    } */
                    {
                        label: 'Occupied',
                        value: 'Occupied'
                    },
                    {
                        label: 'Special Requests for viewing',
                        value: 'Special Request'
                    }
                ],
                element: 'select',
                required: true
            },
            value: undefined
        },
        {
            id: 'ImagesOnly',
            row: { id: 'ImagesOnly', content: ['Information available to brokers', 'Select an option'], selectors: [{ label: 'Documents and images', value: 'No' }, { label: 'Images only', value: 'Yes' }], element: 'select', required: true },
            value: undefined
        },
        {
            id: 'AdvertisementSpaces',
            row: {
                id: 'AdvertisementSpaces',
                content: ['Advertisement spaces amount', 'Select advertisement spaces amount'],
                selectors: [
                    { label: 'Not available', value: '0' },
                    { label: '1 slot available', value: '1' },
                    { label: '2 slots available', value: '2' },
                    { label: '3 slots available', value: '3' },
                    { label: 'Open', value: 'Open' }
                ],
                element: 'select',
                required: true
            },
            value: 'Open'
        },
        {
            id: 'Features',
            row: { id: 'Features', content: ['Outdoor Features', 'Select features'], element: 'select', multiple: true },
            value: undefined
        },
        {
            id: 'Amenities',
            row: { id: 'Amenities', content: ['Amenities Available', 'Select amenities'], element: 'select', multiple: true },
            value: undefined
        },
        {
            id: 'Appliances',
            row: { id: 'Appliances', content: ['Appliances Included', 'Select appliances'], element: 'select', multiple: true },
            value: undefined
        },
        {
            id: 'Fit',
            row: { id: 'Fit', content: ['Interior finishing', 'Select fit'], element: 'select' },
            value: undefined
        },
        {
            id: 'View',
            row: { id: 'View', content: ['View from the property', 'Select view'], element: 'select' },
            value: undefined
        },
        {
            id: 'DisabledWeekdays',
            row: { id: 'DisabledWeekdays', content: ['Disabled weekdays', 'Select the disabled days'], selectors: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], element: 'select', multiple: true },
            value: undefined
        },
        {
            id: 'DisabledTimes',
            row: { id: 'DisabledTimes', content: ['Disabled time', 'Set disabled time'], element: 'input-time-ranges' },
            value: undefined
        },
        // {
        //     id: 'special_requests',
        //     row: { id: 'special_requests', content: ['Special requests for viewing', 'Select option'], selectors: ['Yes', 'No'], element: 'select' },
        //     value: undefined
        // },
        {
            id: 'tenant_requirements',
            row: { id: 'tenant_requirements', content: ['Special requirements', 'Select special requirements'], element: 'select', multiple: true },
            value: undefined
        },
        {
            row: { element: 'separator' },
            userType: 'Landlord'
        },
        {
            row: { content: ['Owners'], element: 'label-primary' },
            userType: 'Landlord'
        },
        {
            id: 'add_owner_button',
            row: { id: 'add_owner_button', content: ['Add owner'], element: 'button' },
            userType: 'Landlord'
        },
        {
            id: 'owners_valid',
            row: { id: 'owners_valid', content: ['Provide at least one owner'], element: 'hidden-input', required: true },
            userType: 'Landlord',
            value: undefined
        },
        {
            id: 'add_poa_button',
            row: { id: 'add_poa_button', content: [], element: 'button' },
            userType: 'Landlord'
        }
    ],
    dynamicForm: [
        {
            id: 'title_deed_preview',
            row: { id: 'title_deed_preview', element: '' },
            value: undefined,
            show: false,
            placeBefore: 'title_deed'
        },
        {
            id: 'marketing_separator',
            row: { element: 'separator' },
            value: undefined,
            show: false
        },
        {
            id: 'marketing_title',
            row: { content: ['Marketing'], element: 'label-primary' },
            value: undefined,
            show: false
        },
        {
            id: 'edit_marketing_button',
            row: { id: 'edit_marketing_button', content: ['Edit marketing'], element: 'action_button', modal: 'edit_unit_marketing_modal' },
            show: false,
            value: undefined
        },
        {
            id: 'OccupiedUntil',
            row: { id: 'OccupiedUntil', content: ['Occupied until'], element: 'input-date' },
            show: false,
            value: undefined,
            placeAfter: 'Statuses'
        },
        {
            id: 'representative.ContactInfo.Name',
            row: { id: 'representative.ContactInfo.Name', content: ['Representative name'], element: 'input', required: true },
            show: false,
            value: undefined,
            placeAfter: 'add_poa_button'
        },
        {
            id: 'representative.ContactInfo.Phone',
            row: { id: 'representative.ContactInfo.Phone', content: ['Representative phone'], element: 'input', regexp: regExps.isPhone },
            show: false,
            value: undefined,
            placeAfter: 'representative.ContactInfo.Name'
        },
        {
            id: 'representative.ContactInfo.Email',
            row: { id: 'representative.ContactInfo.Email', content: ['Representative email'], element: 'input', regexp: regExps.isEmail },
            show: false,
            value: undefined,
            placeAfter: 'representative.ContactInfo.Phone'
        },
        {
            id: 'representative.emirates_id',
            row: { id: 'representative.emirates_id', content: ['Representative emirates id'], element: 'input-file', required: true },
            show: false,
            value: undefined,
            placeAfter: 'representative.ContactInfo.Email'
        },
        {
            id: 'representative.passport',
            row: { id: 'representative.passport', content: ['Representative passport'], element: 'input-file', required: true },
            show: false,
            value: undefined,
            placeAfter: 'representative.emirates_id'
        },
        {
            id: 'representative.POA',
            row: { id: 'representative.POA', content: ['Power of attorney'], element: 'input-file', required: true },
            show: false,
            value: undefined,
            placeAfter: 'representative.passport'
        },
        {
            id: 'SpecialBrokers',
            row: { id: 'SpecialBrokers', content: ['Brokers who can receive documents', 'Select brokers'], element: 'select', multiple: true, showSearch: true },
            show: false,
            value: undefined,
            placeAfter: 'ImagesOnly'
        }
        // {
        //     id: 'representative.separator',
        //     row: { element: 'separator' },
        //     value: undefined,
        //     show: false,
        //     placeAfter: 'representative.POA'
        // }
        /* ,
        {
            id: 'preview_property',
            row: { id: 'preview_property', content: ['Preview property'], element: 'action_button', modal: 'edit_property_modal' },
            show: true,
            value: undefined,
            placeAfter: 'Property'
        } */
    ],
    footerTemplates: {
        set_unit_modal: [
            { id: 'submit', content: ['Add unit'], element: 'button-primary' },
            { id: 'cross-close', content: ['Close'], element: 'button-secondary' }
        ],
        edit_unit_modal: [
            // { id: 'reset', content: ['Archive'], element: 'button-primary' },
            { id: 'submit', content: ['Save changes'], element: 'button-primary' },
            { id: 'cross-close', content: ['Close'], element: 'button-secondary' }
        ]
    },
    titleDeedLoaded: false,
    documentFields: {
        'Scanned.DocumentType': 'Unit',
        'Scanned.DocumentYear': '',
        'Scanned.DocumentNumber': ''
    },
    imagesLoaded: false,
    owners: [],
    representative: {
        passport: undefined,
        emirates_id: undefined,
        poa: undefined
    },
    uploadedDocuments: {},
    formKey: 0,
    loading: false,
    modal: {
        id: 'set_unit_modal',
        header: 'Set unit',
        form: [

        ],
        footer: [] /* {
            set_unit_modal: [
                {id: 'submit', content: ['Add unit'], element: 'button-primary'},
                {id: 'close', content: ['Close'], element: 'button-secondary'}
            ] */
    },
    changeDetected: false
}

export const SpecialRequestSelectors = [{value: 'Smoking', label: 'No smoking'}, {value: 'HasPets', label: 'No pets'}]

export const setUnitSlice = createSlice({
    name: 'setUnit',
    initialState,
    reducers: {
        /**
         * @method setValue
         * @description Sets value in form (staticForm, dynamicForm, owners or documentFields). Gets field by payload.id and sets value to payload.value. Doesn't re-render modal
         */
        setValue (state, action: { payload: { id: string, value: string | string[] | any[] | number | null } }) {
            let row: any = _.find(state.staticForm, formItem => formItem.id as string === action.payload.id)
            if (row == null) {
                row = _.find(state.dynamicForm, formItem => formItem.id === action.payload.id)
            }
            if (row == null) {
                const idSplit = action.payload.id.split('.')
                const owner = _.find(state.owners, owner => owner.id === idSplit[0])
                if (owner?.values != null) {
                    try {
                        // @ts-expect-error
                        owner.values[idSplit[idSplit.length - 1] as OwnerValuesKey] = action.payload.value as string
                    } catch (err) {
                        console.error(err)
                    }
                }
            } else {
            // if (row != null) {
                row.value = action.payload.value
            // }
            }
            if (Object.keys(state.documentFields).includes(action.payload.id)) {
                state.documentFields[action.payload.id as keyof typeof state.documentFields] = action.payload.value as string
            }
        },
        /**
         * @method setTitleDeedLoaded
         * @description Set titleDeed loaded state to action.payload
         */
        setTitleDeedLoaded (state, action) {
            state.titleDeedLoaded = action.payload
        },
        /**
         * @deprecated currently deprecated because images are handled in set_unit_marketing modal
         */
        setImagesLoaded (state, action) {
            state.imagesLoaded = action.payload
        },
        /**
         * @method setRepresentative
         * @description sets representative's documents
         */
        setRepresentative (state, action) {
            state.representative = action.payload
        },
        addUploadedDocument (state, action: { payload: { field: string, base64: string } }) {
            state.uploadedDocuments[action.payload.field] = action.payload.base64
        },
        /**
         * @method addOwner
         * @description adds a new owner to form
         */
        addOwner (state, action) {
            state.owners.push(action.payload)
        },
        /**
         * @method removeOwner
         * @description removes owner from form by owner.id specified in action.payload
         */
        removeOwner (state, action) {
            const owners = _.cloneDeep(state.owners)
            _.remove(owners, owner => owner.id === action.payload)
            state.owners = owners
        },
        /**
         * @method rerenderModal
         * @description simply re-renders set unit modal
         */
        rerenderModal (state) {
            state.formKey++
        },
        /**
         * @method dropSetUnitSlice
         * @description drops all the state related to set unit
         */
        dropSetUnitSlice () {
            return initialState
        },
        /**
         * @method setDynamicRowShow
         * @description sets a dynamicForm element (found by action.paylaod.id) shown or hidden depending on action.payload.state
         */
        setDynamicRowShow (state, action: { payload: { id: string, state: boolean } }) {
            const dynamicForm = _.cloneDeep(state.dynamicForm)
            const formItem = _.find(dynamicForm, item => item.id === action.payload.id)
            if (formItem != null) {
                formItem.show = action.payload.state
            }
            state.dynamicForm = dynamicForm
        },
        /**
         * @method setFooter
         * @description sets the footer for add or edit unit modals
         */
        setFooter (state, action: { payload: string }) {
            state.modal.footer = state.footerTemplates[action.payload]
        },
        /**
         * @method detectChange
         * @description sets the changeDetected flag to action.payload. Used to decide if the state has to be saved before opening edit_marketing_modal.
         * Recommended usage with true as action.payload - dispatch(detectChange(true))
         */
        detectChange (state, action: { payload: boolean }) {
            state.changeDetected = action.payload
        }
    }
})

export const {
    setValue,
    setTitleDeedLoaded,
    setImagesLoaded,
    addOwner,
    removeOwner,
    rerenderModal,
    dropSetUnitSlice,
    setDynamicRowShow,
    setFooter,
    detectChange,
    setRepresentative,
    addUploadedDocument
} = setUnitSlice.actions

export default setUnitSlice.reducer
