import React, { type ReactElement } from 'react'
import { type IMarketing } from '../../../store/slices/unitsSlice'
import Icon from '../../icon/Icon'
import Markdown from 'react-markdown'

interface IProps {
    marketing?: IMarketing
    expanded: boolean
    handleClick: () => void
}

export default function CustomModalForViewRenderDescription({ handleClick, expanded, marketing }: IProps): ReactElement {
    // if (expanded) {
    const descriptionIsEmpty = marketing?.Description == null || marketing?.Description?.trim() === ''
    const description = marketing?.Description?.replaceAll('\n', '  \n')
    console.log(description)
    return descriptionIsEmpty ? <></> :
        <div className={`text-justify fs-5${descriptionIsEmpty ? ' d-flex justify-content-between align-items-center' : ''}`}>
            {/* <label className={'form-label fw-bold fs-4 text-dark m-0 d-flex align-items-center'}>
            <Icon name={'home'} className={'me-2 fs-3x text-dark'} styles={{ minWidth: '2.1rem' }} />
            Description
        </label> */}

            <div className={'rounded rounded-sm bg-gray-300 p-4 mt-4 '}>
                <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase'}>
                    Description
                </label>
                <div className='fw-semi fs-5  lh-1 mt-2'> <Markdown>
                    {descriptionIsEmpty
                        ? 'Not set'
                        : description
                    }
                </Markdown></div>
            </div>
        </div>
    // }

    // return (
    //     <div className="d-flex flex-column align-items-end ">
    //         <div className="">
    //             {marketing?.Description?.length && marketing.Description.length > 140
    //                 ? marketing.Description.slice(0, 145) + '...'
    //                 : marketing?.Description ?? ''}
    //         </div>
    //         {marketing?.Description?.length > 140 && (
    //             <div
    //                 className="btn btn-sm p-2 btn-light-dark  w-25 text-dark border border-dark fs-6 text-dark text-uppercase"
    //                 onClick={handleClick}
    //             >
    //                 View More
    //             </div>
    //         )}
    //     </div>
    // )
}
